import App from "@/App.vue";
import Vue from "vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import vueauth from "common/plugins/vueauth.js";
import vueapi from "common/plugins/vueapi";
import vueprofile from "common/plugins/vueprofile";
import VueMasonry from "vue-masonry-css";
import VueConfetti from "vue-confetti";
import PortalVue from "portal-vue";
import config from "./config.js";

Vue.config.productionTip = false;

Vue.use(vueapi);
Vue.use(vueauth);
Vue.use(vueprofile);
Vue.use(VueMasonry);
Vue.use(VueConfetti);
Vue.use(PortalVue);

export const app = new Vue({
  data: { settings: { showIcal: true } },
  vuetify,
  router,
  render(h) {
    return h(App);
  },
});
app.$auth.initConfiguration(config);
app.$mount("#app");
