export default [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings/"),
  },
  {
    path: "/ical",
    name: "ICalendar",
    component: () => import("@/views/ICalendar/"),
  },
  {
    path: "/releaseNotes",
    name: "ReleaseNotes",
    component: () => import("@/views/ReleaseNotes/"),
  },

  {
    path: "/playground",
    name: "Playground",
    component: () => import("@/views/Playground/"),
    dev: true,
  },
];
